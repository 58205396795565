
const config: IConfig = {
serve: 'http://rap2.youshishuke.com:8188',
  keys: ['some secret hurr'],
  session: {
    key: 'koa:sess',
  },
}

export default config
